* {
    margin: 0;
    padding: 0;
}

.controles {
    /* display: block; */
    position: relative;
    margin: 0px auto;
    /* width: 40%; */
    padding: 5px;
    border: none;
    border-radius: 7px;
    /* font-size: 15px; */
    /* font-family: Montserrat; */
    text-align: center;
    color: #000;
}